import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";


const routes = [
  {
    path: '/login',
    name: '登录',
    component: () => import('../viwes/Login/Login'),
    meta: { requireAuth: true },
    children: []
  },
  {
    path: '/newdata',
    name: '营业数据',
    component: () => import('../viwes/Data/NewData'),
    meta: { requireAuth: true },
    children: []
  },
   {
        path: '/',
        name:'',
        component: () => import('../viwes/Index/Index'),
        meta: {requireAuth: true},
        children: [
          {
            path: '/data',
            name:'数据页面',
            component: () => import('../viwes/Data/Data'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/timbre/list',
            name:'语言列表',
            component: () => import('../viwes/Timbre/TimList'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/timbre/type',
            name:'语言类型',
            component: () => import('../viwes/Timbre/TimType'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/home/list',
            name:'视频列表',
            component: () => import('../viwes/Home/HoList'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/home/make',
            name: 'make',
            component: () => import('../viwes/Home/Make'),
            meta: { requireAuth: true },
            children: []
          }, 
          {
            path: '/customization/list',
            name:'审核列表',
            component: () => import('../viwes/Customization/CustList'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/customization/dilist',
            name:'定制列表',
            component: () => import('../viwes/Customization/DiList'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/customization/custconfig',
            name:'页面配置',
            component: () => import('../viwes/Customization/CustConfig'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/systemconfig/privacypolicylist',
            name:'页面内容',
            component: () => import('../viwes/SystemConfig/PrivacyPolicyList'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/resou/fontlist',
            name:'字体列表',
            component: () => import('../viwes/Resou/FontList'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/copy/cotype',
            name:'文库类型',
            component: () => import('../viwes/Copy/CoType'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/copy/colist',
            name:'文库列表',
            component: () => import('../viwes/Copy/CoList'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/other/feedback',
            name:'反馈列表',
            component: () => import('../viwes/SystemConfig/Feedback'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/systemconfig/peiconfig',
            name:'系统设置',
            component: () => import('../viwes/SystemConfig/PeiConfig'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/other/newlist',
            name:'新闻列表',
            component: () => import('../viwes/SystemConfig/NewList'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/user/list',
            name:'用户列表',
            component: () => import('../viwes/User/List'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/finance/list',
            name:'财务明细',
            component: () => import('../viwes/Finance/List'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/resou/imagetype',
            name:'图片数字人分类',
            component: () => import('../viwes/Resou/ImageType'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/resou/ornament',
            name:'饰品分类',
            component: () => import('../viwes/Resou/ornament'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/resou/background',
            name:'背景分类',
            component: () => import('../viwes/Resou/background'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/resou/musicList',
            name:'音乐',
            component: () => import('../viwes/Resou/musicList'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/resou/imagelist',
            name:'图片数字人列表',
            component: () => import('../viwes/Resou/ImageList'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/other/memberrotation',
            name:'会员轮播',
            component: () => import('../viwes/SystemConfig/MemberRotation'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/systemconfig/notice',
            name:'首页弹窗',
            component: () => import('../viwes/SystemConfig/Notice'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/resou/typelist',
            name:'分类管理',
            component: () => import('../viwes/Resou/TypeList'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/resou/videolist',
            name:'数字人广场',
            component: () => import('../viwes/Resou/VideoList'),
            meta: {requireAuth: true},
            children: []
          },
          {
            path: '/resou/templatelist',
            name:'模板廣場',
            component: () => import('../viwes/Resou/TemplateList'),
            meta: {requireAuth: true},
            children: []
          }
         
        ]
     }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;