import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import store from './store'
import '../src/assets/assets.css'  
// import '../src/static/font_icon/iconfont.css';  

import 'es-drager/lib/style.css'
import Drager from 'es-drager'

const app = createApp(App)

app.use(router)
app.use(ElementPlus)
app.use(store)
store.commit("getadmindata");
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.component('es-drager', Drager).mount('#app')



app.config.silent = true;
